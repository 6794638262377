<template>
<div>
  <v-btn
    @click="ready?func():null"
    elevation="2"
    :color="ready?'primary':'grey'"
    dark
    fab
    x-large
    fixed
    bottom
    right
    ><img src="@/assets/logo_white.png" class="knop">
  </v-btn>
  <v-snackbar
    :timeout="5000"
    :value="result.trigger"
    width="80%"
    :color="result.color"
  >
    <center>
      <h3>{{result.text}}</h3>
    </center>
  </v-snackbar>
</div>
</template>

<script>
export default {
  name: 'CheckOut',
  props: ['func', 'status', 'ready'],
  computed: {
    result () {
      if (this.status === true) {
        return {
          trigger: true,
          color: 'success',
          text: 'Erfolgreich'
        }
      } else if (this.status === false) {
        return {
          trigger: true,
          color: 'error',
          text: 'Da lief was falsch'
        }
      } else {
        return {
          trigger: false,
          color: '',
          text: ''
        }
      }
    }
  }
}
</script>

<style scoped>
.knop {
  width: 48px;
  transition: width 500ms;
}
.knop:hover {
  width: 56px;
}
</style>
